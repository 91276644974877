<template>
  <div class="oppo-container">
    <van-field
      v-model="value"
      label="账号"
      placeholder="请输入需要添加的账号"
      type="textarea"
      rows="20"
    />
    <van-button type="primary" size="large" @click="submit">提交</van-button>
  </div>
</template>
<script>
import { Field, Button, Toast } from 'vant'
import { mapActions } from 'vuex'

export default {
  components: {
    'van-field': Field,
    'van-button': Button,
  },
  data() {
    return {
      value: '',
    }
  },
  methods: {
    ...mapActions('oppo', ['addOppoAccounts']),
    submit() {
      if (!this.value.trim()) {
        Toast.fail({
          message: '数据不能为空',
          duration: 3000,
        })
        return
      }
      let accounts = this.value.split('\n').map((act) => {
        act = act.trim()
        act = act.split('----')
        console.log(act)
        return {
          account: act[0].trim(),
          pwd: act[1].trim(),
        }
      })
      if (!accounts.length) {
        Toast.fail({
          message: '数据不能为空或者格式错误',
          duration: 3000,
        })
        return
      }
      console.log(accounts)

      this.addOppoAccounts({ accounts })
        .then(() => {
          Toast.success('添加成功')
          this.value = ''
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },
}
</script>
<style lang="less" scoped>
.oppo-container {
  padding: 0 20px;
}
</style>
