<template>
  <van-form class="game-form">
    <div style="font-size: 12px;color: #f00">
      <p>
        <span>新开区服可以领取新服奖励，每个账号只能领取一次！</span>
      </p>
    </div>
    <div class="hr"></div>
    <van-field
      readonly
      clickable
      name="serverid"
      :value="servername"
      label="区服"
      placeholder="请选择区服"
      @click="serverChoose = true"
    ></van-field>
    <van-popup v-model="serverChoose" position="bottom">
      <van-picker
        show-toolbar
        :columns="serverList"
        value-key="configname"
        @confirm="serverChoosed"
        @cancel="serverChoose = false"
      />
    </van-popup>
    <van-field
      v-model="account"
      name="account"
      label="账号"
      placeholder="请输入账号"
      :rules="[{ required: true, message: '请输入账号' }]"
      @change="accountChangeHandler"
      clearable
    ></van-field>
    <van-field
      readonly
      clickable
      name="charguidl"
      :value="charname"
      placeholder="请选择角色"
      label="角色"
      @click="roleChooseHandler"
    ></van-field>
    <van-popup v-model="roleChoose" position="bottom">
      <van-picker
        :loading="roleLoading"
        show-toolbar
        :columns="roleList"
        value-key="charname"
        @confirm="roleChoosed"
        @cancel="roleChoose = false"
      />
    </van-popup>
    <van-field
      name="captcha"
      v-model="captcha"
      placeholder="请输入验证码"
      label="验证码"
      :rules="[{ required: true, message: '请输入验证码' }]"
      clearable
    >
      <template #button>
        <van-image
          :src="picPath"
          width="90px"
          height="30px"
          @click="changeCaptcha"
        ></van-image>
      </template>
    </van-field>
    <div class="btn-group">
      <div v-for="b in rewardList" :key="b.ID" class="b-item">
        <van-button
          size="large"
          color="linear-gradient(to right, #ff6034, #ee0a24)"
          @click="sendRewardHandler(b)"
          >{{ b.name }}</van-button
        >
      </div>
    </div>
  </van-form>
</template>
<script>
import { Image, Form, Field, Picker, Popup, Button, Toast, Notify } from 'vant'
import LeftImage from '@/assets/images/bg_left.png'
import RightImage from '@/assets/images/bg_right.png'
import GameLogo from '@/assets/images/logo.png'
import { mapActions } from 'vuex'

export default {
  name: 'NewAreaReward',
  components: {
    // 'van-button': Button,
    'van-image': Image,
    'van-form': Form,
    'van-field': Field,
    'van-picker': Picker,
    'van-popup': Popup,
    'van-button': Button,
  },
  metaInfo() {
    return {
      title: '新区福利',
    }
  },
  data() {
    return {
      LeftImage,
      RightImage,
      GameLogo,
      account: '',
      serverChoose: false,
      serverList: [],
      servername: '',
      serverid: 0,
      charname: '',
      roleChoose: false,
      charguidl: 0,
      roleList: [],
      captcha: '',
      roleLoading: false,
      captchaId: '',
      picPath: '',
      rewardList: [],
    }
  },
  async mounted() {
    let loading = Toast.loading({
      message: '加载中...',
      forbidClick: true,
      duration: 0,
    })
    try {
      let account = window.localStorage.getItem('account')
      if (account) {
        this.account = account
      }

      await this.changeCaptcha()
      let serverData = await this.getServerByNewArea()
      this.serverList = serverData.data.list
      let bConfigData = await this.getListByNewArea()
      this.rewardList = bConfigData.data.list

      loading.clear()
    } catch (err) {
      console.log(err)
      loading.clear()
    }
  },
  methods: {
    ...mapActions('new_area', [
      'sendNewAreaReward',
      'getServerByNewArea',
      'getListByNewArea',
      'getNameByNewArea',
      'captchaByNewArea',
    ]),
    dataInit() {
      this.roleList = []
      this.charname = ''
      this.charguidl = 0
    },
    changeCaptcha() {
      this.picPath = ''
      this.captchaId = ''
      // 获取验证码
      this.captchaByNewArea()
        .then(({ data }) => {
          this.picPath = data.picPath
          this.captchaId = data.captchaId
        })
        .catch((err) => {
          console.log(err)
        })
    },
    accountChangeHandler() {
      this.dataInit()
    },
    serverChoosed(value) {
      this.serverid = value.ID
      this.servername = value.configname
      this.serverChoose = false
      this.dataInit()
    },

    roleChoosed(value) {
      this.charguidl = value.charguidl
      this.charname = value.charname
      this.roleChoose = false
    },
    roleChooseHandler() {
      this.roleLoading = true
      this.roleChoose = true
      this.getNameByNewArea({
        serverid: this.serverid,
        username: this.account,
      })
        .then(({ data }) => {
          this.roleList = data.list
          this.roleLoading = false
        })
        .catch((err) => {
          console.log(err)
          this.roleLoading = false
          this.roleChoose = false
        })
    },
    sendRewardHandler(reword) {
      let loading = Toast.loading({
        message: '加载中...',
        forbidClick: true,
        duration: 0,
      })
      this.sendNewAreaReward({
        account: this.account,
        captcha: this.captcha,
        captchaId: this.captchaId,
        charguidl: this.charguidl,
        charname: this.charname,
        configids: reword.ID,
        configname: reword.name,
        serverid: this.serverid,
        servername: this.servername,
      })
        .then(() => {
          loading.clear()
          Notify({ type: 'success', message: '领取成功' })
          window.localStorage.setItem('account', this.account)
          this.changeCaptcha()
        })
        .catch((err) => {
          console.log(err)
          Notify({ type: 'danger', message: err.msg })
          this.changeCaptcha()
          loading.clear()
        })
    },
  },
}
</script>

<style lang="less" scoped>
.hr {
  height: 10px;
  width: 100%;
}

.game-form {
  width: 90%;
  margin: 10px auto;
}

.btn-group {
  display: flex;
  flex-wrap: wrap;

  .b-item {
    flex-basis: 50%;
    padding-right: 10px;
    box-sizing: border-box;
    margin-top: 10px;
  }
}
</style>
